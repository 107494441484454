import "./SelectedUser.scss";
import React from "react";
import { Image } from "react-bootstrap";
import { PersonFill, XCircleFill } from "react-bootstrap-icons";

const SelectedUser = (props) => {
    return (
        <div id="selectedUser" className="user--selected">
            <div className="user">
                <div className="user--profile-photo">
                    {props.selectedUser.photoUrl ? (
                        <Image
                            className="user--profile-photo--image"
                            src={props.selectedUser.photoUrl}
                            alt={props.selectedUser.name}
                        />
                    ) : (
                        <PersonFill color={`#CCCCCC`} size={75} />
                    )}
                </div>
                <div className="user--details">
                    <div className="user--name">{props.selectedUser.name}</div>
                    <div className="user--meta">
                        {props.selectedUser.location ? (
                            <span className="user--location">
                                {`${props.selectedUser.location} -`}
                            </span>
                        ) : null}
                        <span className="user--specialty">
                            {props.selectedUser.specialty}
                        </span>
                    </div>
                </div>
            </div>
            <XCircleFill
                style={{ display: props.hideRemoveButton ? "none" : "block" }}
                className="button__remove"
                color={`#CCC`}
                onClick={() => {
                    props.resetForm();
                }}
                size={16}
            />
        </div>
    );
};

export default SelectedUser;
