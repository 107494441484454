import "./OptIn.scss";
import React from "react";
import Form from "react-bootstrap/Form";

const OptIn = (props) => {
    return (
        <Form.Group>
            <Form.Check required className="mt-2 mb-1" id="opt-in">
                <Form.Check.Input type="checkbox" onChange={props.onToggle} />
                <Form.Check.Label>
                    <div className="checkbox__label">
                        {props.label ||
                            "Yes, I want to receive Medical Information and Promotional messages about Vidscrip Programs."}
                    </div>
                    <div
                        className="disclaimer__fine-print"
                        style={{ marginLeft: 2 }}
                    >
                        Message frequency Varies. Message and data rates may
                        apply. Text STOP to opt-out of receiving messages. Text
                        HELP for support. Vidscrip will never share personally
                        identifiable information with anybody. To learn more
                        read our{` `}
                        <a
                            href="https://www.vidscrip.com/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            privacy policy
                        </a>
                        {` and `}
                        <a
                            href="https://www.vidscrip.com/smsterms"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            SMS terms
                        </a>
                        .
                    </div>
                </Form.Check.Label>
            </Form.Check>
        </Form.Group>
    );
};

export default OptIn;
