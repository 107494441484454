import React from "react";
import { OverlayTrigger, Popover, Row, Tabs, Tab } from "react-bootstrap";
import CSVReader from "react-csv-reader";
import EnrollSingle from "./EnrollSingle";

const EnrollMultiple = (props) => {
    return (
        <>
            <Tabs
                defaultActiveKey="single"
                id="enrollmentOptions"
                transition={false}
            >
                <Tab
                    className="mb-3 tab__enrollment"
                    eventKey="single"
                    title="Add a Patient"
                >
                    <EnrollSingle
                        buttonTooltip={`Number added!`}
                        eventDateLabel={props.eventDateLabel}
                        formRef={props.formRef}
                        handleAdd={props.handleAdd}
                        showEventDate={props.showEventDate}
                        mobileNumber={props.mobileNumber}
                        mobileNumberInput={props.mobileNumberInput}
                        eventDate={props.eventDate}
                        onFocusMobileNumber={() => {
                            props.setShowButtonTooltip(false);
                        }}
                        showButtonTooltip={props.showButtonTooltip}
                        setMobileNumber={props.setMobileNumber}
                        setEventDate={props.setEventDate}
                        selectedUser={props.selectedUser}
                        showOptIn={false}
                        handleToggleOptIn={props.handleToggle}
                        phoneLabel={`Patient Mobile Number:`}
                        disabled={
                            !props.selectedUser ||
                            !props.selectedCampaign ||
                            !props.isValidMobileNumber ||
                            !props.isValidDate
                        }
                    />
                </Tab>
                <Tab
                    className="mb-3 tab__enrollment"
                    eventKey="multiple"
                    title="Import CSV"
                >
                    <h5>How to bulk enroll using a .csv file:</h5>
                    <Row>
                        <ol>
                            <li>
                                {`Create a spreadsheet that includes "phone" and "date" header columns in Row 1 `}
                                <ul>
                                    <li>
                                        <a
                                            href="https://assets.vidscrip.com/assets/vidscrip-sample.xlsx"
                                            download={true}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {`Start with a sample .xslx file for Microsoft Excel`}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`http://vidsc.rip/enrollment-form-sample`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {`Start with a template for Google Sheets`}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>{`Enter patient mobile numbers and procedure dates under the "phone" and "date" columns`}</li>
                            <li>
                                {`Export your data as a CSV file`}
                                <ul>
                                    <li>
                                        <OverlayTrigger
                                            show={props.showExcelTooltip}
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-excelTooltip">
                                                    <Popover.Title as="h3">{`Export a CSV using Microsoft Excel`}</Popover.Title>
                                                    <Popover.Content>
                                                        <ol>
                                                            <li>{`Click File >> Save As >> Save As Type ... `}</li>
                                                            <li>{`Select CSV (Comma delimited) (*.csv)`}</li>
                                                            <li>{`Click Save`}</li>
                                                        </ol>
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                        >
                                            <span
                                                className="tooltip__toggle"
                                                onClick={
                                                    props.handleClickExportUsingExcel
                                                }
                                            >{`Export a .csv using Microsoft Excel`}</span>
                                        </OverlayTrigger>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                            show={props.showSheetsTooltip}
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-sheetsTooltip">
                                                    <Popover.Title as="h3">{`Export a CSV using Google Sheets`}</Popover.Title>
                                                    <Popover.Content>
                                                        <ol>
                                                            <li>{`Click File >> Download`}</li>
                                                            <li>{`Click Comma-seperated values (.csv, current sheet)`}</li>
                                                        </ol>
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                        >
                                            <span
                                                className="tooltip__toggle"
                                                onClick={
                                                    props.handleClickExportUsingSheets
                                                }
                                            >
                                                {`Export a .csv using Google Sheets`}
                                            </span>
                                        </OverlayTrigger>
                                    </li>
                                </ul>
                            </li>
                            <li>{`Click "Choose File" button below >> Select your .csv file`}</li>
                            <li>{`Click "Submit" button below to complete your enrollments`}</li>
                        </ol>
                    </Row>
                    <CSVReader
                        label={``}
                        onFileLoaded={props.handleCSV}
                        onError={props.handleCSVError}
                        parserOptions={{
                            header: true,
                            skipEmptyLines: true,
                            transformHeader: (header) =>
                                header.toLowerCase().replace(/\W/g, "_"),
                        }}
                        inputId="importCsv"
                    />
                </Tab>
            </Tabs>
        </>
    );
};

export default EnrollMultiple;
