import React from "react";
import EnrollmentForm from "./EnrollmentForm";
import Container from "react-bootstrap/Container";
import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";
import "./App.scss";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp() {
    return (
        <Container className="EnrollmentFormApp" fluid>
            <EnrollmentForm />
            <small>Version: {getBuildDate(packageJson.buildDate)}</small>
        </Container>
    );
}

export default App;
