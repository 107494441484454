import React from "react";
import Form from "react-bootstrap/Form";
import SelectedUser from "./SelectedUser";

import { useQuery } from "./Utils";

const SelectCampaign = (props) => {
    const queryCampaignLabel = useQuery().get("campaignLabel");
    const campaignLabel =
        queryCampaignLabel ||
        (props.isImportEnabled
            ? `Patient's Appointment/Procedure:`
            : `I want to sign up for:`);

    return (
        <Form.Group className="form-group__select-a-procedure">
            <SelectedUser {...props} />
            <Form.Label>{campaignLabel}</Form.Label>
            {props.campaigns.map((p) => {
                return (
                    <Form.Check
                        className="campaign"
                        id={`campaign-${p.airtableId}`}
                        key={`campaign-${p.airtableId}`}
                    >
                        <Form.Check.Input
                            onChange={() => props.onChange(p)}
                            type="radio"
                            checked={
                                props.selectedCampaign
                                    ? p.airtableId ===
                                      props.selectedCampaign.airtableId
                                    : false
                            }
                        />
                        <Form.Check.Label>
                            <div className="campaign__name">{p.name}</div>
                            <div className="campaign__description">
                                {p.whoShouldEnroll}
                            </div>
                        </Form.Check.Label>
                    </Form.Check>
                );
            })}
        </Form.Group>
    );
};

export default SelectCampaign;
