import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import OptIn from "./OptIn";

const AddButton = (props) => {
    return (
        <Button
            ref={props.mobileNumberInput}
            disabled={props.disabled}
            className="mb-3 mt-3"
            onClick={props.handleAdd}
            variant={props.buttonVariant || "secondary"}
            type="button"
        >
            {props.buttonLabel || `Add`}
        </Button>
    );
};

const EnrollSingle = (props) => {
    const {
        disabled,
        eventDateLabel,
        eventDate,
        formRef,
        handleAdd,
        handleToggleOptIn,
        isOptedIn,
        mobileNumber,
        onFocusMobileNumber,
        popoverTitle,
        popoverContent,
        showButtonTooltip,
        showOptIn,
        setMobileNumber,
        setEventDate,
        showEventDate,
    } = props;

    const hideOptIn = showOptIn === false;

    const popover = (
        <Popover id="submit-message">
            <Popover.Title>
                {popoverTitle || `Mobile number added.`}
            </Popover.Title>
            <Popover.Content>
                {popoverContent ||
                    `Add more mobile numbers or click the Submit button below to complete enrollment.`}
            </Popover.Content>
        </Popover>
    );

    const handleKeyDown = (e) => {
        // Enter button
        if (e.keyCode === 13 && !disabled) {
            handleAdd();
        }
    };

    return (
        <>
            {showEventDate ? (
                <Form.Group>
                    <Form.Label>{eventDateLabel}</Form.Label>
                    <br />
                    <DatePicker
                        className="form-control"
                        selected={eventDate && parseISO(eventDate)}
                        onChange={(date) => {
                            const dateFormatted = date
                                ? format(date, "yyyy-MM-dd")
                                : null;
                            setEventDate(dateFormatted);
                        }}
                    />
                </Form.Group>
            ) : null}
            <Form.Group>
                <PhoneInput
                    id="mobileNumber"
                    country={"us"}
                    countryCodeEditable={false}
                    onlyCountries={["us"]}
                    inputProps={{
                        name: "mobileNumber",
                        required: true,
                    }}
                    specialLabel={props.phoneLabel || `Mobile #`}
                    inputStyle={{ marginTop: "0.5rem" }}
                    onChange={() => {
                        setMobileNumber(formRef.current["mobileNumber"].value);
                    }}
                    onFocus={onFocusMobileNumber}
                    onKeyDown={handleKeyDown}
                    value={mobileNumber}
                />
            </Form.Group>
            {!hideOptIn ? (
                <OptIn
                    checked={isOptedIn}
                    onToggle={handleToggleOptIn}
                    label={props.optInLabel}
                    selectedCampaign={props.selectedCampaign}
                />
            ) : null}

            {showButtonTooltip ? (
                <OverlayTrigger
                    placement="right"
                    overlay={popover}
                    show={showButtonTooltip}
                >
                    <AddButton {...props} disabled={disabled} />
                </OverlayTrigger>
            ) : (
                <AddButton {...props} disabled={disabled} />
            )}
        </>
    );
};

export default EnrollSingle;
