// const API_URL = "https://api.vidscrip.com/api/v1/";
const API_URL = process.env.REACT_APP_VIDSCRIP_API_URL;
const API_ANONYMOUS_TOKEN = process.env.REACT_APP_VIDSCRIP_API_TOKEN;
const headers = {
    Authorization: `Bearer ${API_ANONYMOUS_TOKEN}`,
    "Content-Type": "application/json",
};

export const getUserByChannel = async (channel) => {
    try {
        const populate = ["locations"].join('","');
        const response = await fetch(
            `${API_URL}users/channel_url/${channel}?populate=["${populate}"]`,
            {
                headers: {
                    Authorization: `Bearer ${API_ANONYMOUS_TOKEN}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const { success, data } = await response.json();
        if (!success) {
            throw new Error(`An error occurred`, response);
        }
        return data.users?.length > 0 ? data.users[0] : null;
    } catch (err) {
        throw err;
    }
};

export const searchUsers = async (term) => {
    try {
        const route = `${API_URL}campaigns/search?q=${term}`;
        const response = await fetch(route, { headers });
        const { success, data } = await response.json();

        if (!success) {
            throw new Error(`An unknown error occurred.`);
        }

        return data.users;
    } catch (err) {
        throw err;
    }
};

export const getProgramMembershipsForUser = async (user) => {
    try {
        const response = await fetch(
            `${API_URL}users/${user._id}/find_sms_memberships`,
            { headers }
        );
        const { success, data } = await response.json();
        if (!success) {
            throw new Error(`An error occurred`, response);
        }
        return data.memberships;
    } catch (err) {
        throw err;
    }
};

export const getProgramMembershipsByChannelUrl = async ({
    campaignChannels,
    userChannels,
}) => {
    try {
        const populate = ["users.owner.locations"].join('","');

        let route = `${API_URL}memberships/?populate=["${populate}"]&campaignChannels[]=${campaignChannels.join(
            "&campaignChannels[]="
        )}`;

        if (userChannels) {
            route = `${route}&userChannels[]=${userChannels.join(
                "&userChannels[]="
            )}`;
        }

        const response = await fetch(route, { headers });
        const { success, data } = await response.json();
        if (!success) {
            return { programs: [], users: [] };
        }
        const memberships = data.memberships.map((m) => {
            m.group = data.groups.find((group) => group._id === m.group);
            return m;
        });
        return memberships;
    } catch (err) {
        throw err;
    }
};

export const getCampaignsForUser = async (user) => {
    try {
        const route = `${API_URL}users/${user.airtableId}/campaigns`;
        const response = await fetch(route, { headers });
        const { success, data } = await response.json();

        if (!success) {
            throw new Error(`An unknown error occurred.`);
        }

        return data.campaigns;
    } catch (err) {
        throw err;
    }
};

export const getCampaign = async (campaignId) => {
    try {
        const route = `${API_URL}campaigns/${campaignId}`;
        const response = await fetch(route, { headers });
        const { success, data } = await response.json();

        if (!success) {
            throw new Error(`An unknown error occurred.`);
        }

        return data.campaign;
    } catch (err) {
        throw err;
    }
};

export const getUserRecord = async (userId) => {
    try {
        const route = `${API_URL}users/${userId}/sms_record`;
        const response = await fetch(route, { headers });
        const { success, data } = await response.json();

        if (!success) {
            throw new Error(`An unknown error occurred.`);
        }

        return data.user;
    } catch (err) {
        throw err;
    }
};
