import "./EnrollmentForm.scss";

import * as moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import {
    getCampaignsForUser,
    getCampaign,
    getUserRecord,
    searchUsers,
} from "./VidscripApi";

import { Alert } from "react-bootstrap";
import EnrollSingle from "./EnrollSingle";
import OptIn from "./OptIn";
import SelectUser from "./SelectUser";
import SelectCampaign from "./SelectCampaign";
import { useQuery, inIframe, formatPhoneNumber } from "./Utils";

import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import EnrollMultiple from "./EnrollMultiple";
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Paper,
} from "@material-ui/core";

const VIDSCRIP_CONNECT_API = process.env.REACT_APP_VIDSCRIP_CONNECT_API;
const VIDSCRIP_CONNECT_API_KEY = process.env.REACT_APP_VIDSCRIP_CONNECT_API_KEY;

const EnrollmentForm = () => {
    const queryEventDate = useQuery().get("eventDate");
    const queryEventDateLabel = useQuery().get("eventDateLabel");
    const queryCampaign = useQuery().get("campaign");
    const queryKeyword = useQuery().get("keyword");
    const queryUser = useQuery().get("user");
    const today = moment().format("YYYY-MM-DD");

    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [campaigns, setCampaigns] = useState();
    const [users, setUsers] = useState();
    const [submissions, setSubmissions] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedCampaign, setSelectedCampaign] = useState();

    const [mobileNumber, setMobileNumber] = useState();
    const [isOptedIn, setIsOptedIn] = useState(false);
    const [isQuickEnrolling, setIsQuickEnrolling] = useState(false);
    const [isImportEnabled] = useState(
        Number(useQuery().get("enableImport")) === 1
    );
    const [showUser, setShowUser] = useState(
        !(Number(useQuery().get("hideUser")) === 1)
    );
    const showCampaign = !(Number(useQuery().get("hideCampaign")) === 1);
    const [showEventDate, setShowEventDate] = useState(
        !(Number(useQuery().get("hideEventDate")) === 1) || false
    );

    const [defaultEventDate, setDefaultEventDate] = useState(
        queryEventDate
            ? moment(queryEventDate).format("YYYY-MM-DD")
            : !showEventDate && today
    );

    // Set event date to the value of url param eventDate, or
    // if the url param hideEventDate=1 is set, set the value to today.
    const [eventDate, setEventDate] = useState(defaultEventDate);
    const [showRemoveButton, setShowRemoveButton] = useState(true);

    const referrer =
        inIframe() &&
        document.referrer !== "" &&
        document.referrer !== document.location.origin
            ? document.referrer
            : document.location.origin;

    const referrerType = queryKeyword ? "Keyword" : "URL";

    const eventDateLabel =
        queryEventDateLabel ||
        (isImportEnabled
            ? `Patient Appointment/Procedure Date:`
            : `What date is your appointment/procedure?`);

    const isValidMobileNumber = mobileNumber && mobileNumber.length === 17;
    const isValidDate = isValid(parseISO(eventDate));

    let formRef = useRef();
    let mobileNumberInput = useRef();
    const submissionsRef = useRef();

    const handleDeselectUser = useCallback(
        (selectUserRef) => {
            if (selectedUser) {
                setSelectedUser(undefined);
                selectUserRef.current.clear();
                selectUserRef.current.focus();
            }
        },
        [selectedUser]
    );

    const handleSelectUser = useCallback(
        ([user]) => {
            if (!isSearching && user !== selectedUser) {
                setSelectedUser(user);

                const replaceTagsInString = (string) => {
                    if (!string || string.length === "") {
                        return string;
                    }

                    const tags = {
                        "{User.Name}": user.name,
                    };

                    const re = new RegExp(Object.keys(tags).join("|"), "gi");

                    const newString = string.replace(re, (matched) => {
                        return tags[matched];
                    });

                    return newString;
                };
                const selectUser = async (user) => {
                    setIsLoading(true);
                    try {
                        let campaigns = await getCampaignsForUser(user);
                        campaigns = campaigns.map((c) => {
                            return {
                                ...c,
                                whoShouldEnroll: replaceTagsInString(
                                    c.whoShouldEnroll
                                ),
                            };
                        });
                        setCampaigns(campaigns);
                        setIsLoading(false);
                    } catch (err) {
                        console.error(err);
                        setIsLoading(false);
                    }
                };
                user && selectUser(user) && setShowUser(false);
            }
        },
        [isSearching, selectedUser]
    );

    const resetForm = useCallback(() => {
        setSelectedUser(undefined);
        setSelectedCampaign(undefined);
        setCampaigns(undefined);
        setUsers(undefined);
        setIsOptedIn(false);
        setMobileNumber("1");
        setEventDate(defaultEventDate);
        setSubmissions([]);
        setShowUser(true);
    }, [
        defaultEventDate,
        setSelectedCampaign,
        setSelectedUser,
        setEventDate,
        setMobileNumber,
        setSubmissions,
    ]);

    const enrollSubmissions = useCallback(async () => {
        setIsLoading(true);

        try {
            const responses = await Promise.all(
                submissions.map(async (s) => {
                    let body = {
                        campaign: {
                            to: s.phone,
                            date: moment(s.date, "MM/DD/YYYY").format(
                                "YYYY-MM-DD"
                            ),
                            airtableId: s.campaign.airtableId,
                        },
                    };

                    if (referrer) {
                        body.campaign.referrer = {
                            referrer,
                            referrerType,
                            keyword: queryKeyword,
                        };
                    }

                    body = JSON.stringify(body);
                    let response = await fetch(
                        `${VIDSCRIP_CONNECT_API}campaigns`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                apiKey: VIDSCRIP_CONNECT_API_KEY,
                            },
                            body,
                        }
                    );
                    const { success, message, error } = await response.json();
                    success
                        ? console.log(
                              `${s.phone} enrolled in ${selectedCampaign.name} by ${selectedUser.name}`
                          )
                        : console.log(
                              `Failed to enroll ${s.phone} in ${selectedCampaign.name} by ${selectedUser.name}`
                          );
                    return {
                        submission: s,
                        success,
                        message,
                        error,
                    };
                })
            );
            const errors = responses.filter((r) => r.error);
            let alerts = errors.map((e) => ({
                ...e,
                status: "danger",
                message: `${
                    e.message || e.error
                        ? e.message || e.error
                        : `An unknown error occurred.`
                }`,
            }));
            const successes = responses.filter((r) => r.success);
            if (successes.length > 0) {
                alerts = alerts.concat([
                    {
                        status: "success",
                        message:
                            successes.length === 1
                                ? `Well done! ${submissions[0].phone} enrolled in "${selectedCampaign.name}" by ${selectedUser.name}`
                                : `Well done! ${successes.length} numbers were enrolled successfully.`,
                    },
                ]);
            }
            setAlerts(alerts);
            resetForm();
            setIsLoading(false);
            formRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } catch (error) {
            alert(`An error occurred: ${error}`);
            setIsLoading(false);
        }
    }, [
        resetForm,
        selectedCampaign,
        selectedUser,
        submissions,
        referrer,
        referrerType,
        queryKeyword,
    ]);

    const handleSubmit = (e) => {
        e.preventDefault();
        enrollSubmissions();
    };

    const handleSearch = async (query) => {
        try {
            setAlerts([]);
            if (selectedCampaign) {
                setSelectedCampaign(undefined);
                setCampaigns(undefined);
                setEventDate(defaultEventDate);
            }
            setIsSearching(true);
            const results = await searchUsers(query);
            setUsers(results.sort((a, b) => a.name.localeCompare(b.name)));
            setIsSearching(false);
        } catch (err) {
            console.error(`[Vidscrip]`, "Error", err);
            setIsSearching(false);
            setAlerts([
                {
                    status: "danger",
                    message: (
                        <>
                            Hm. It appears we're having trouble connecting to
                            Vidscrip. Please try again later. For immediate
                            support contact&nbsp;
                            <a href="mailto:help@vidscrip.com">
                                help@vidscrip.com
                            </a>
                        </>
                    ),
                },
            ]);
        }
    };

    const handleSelectCampaign = (campaign) => {
        setShowEventDate(!campaign.hasQuickEnroll);
        setEventDate(campaign.hasQuickEnroll ? today : undefined);
        setSelectedCampaign(campaign);
    };

    const handleCSV = (data, fileInfo) => {
        data = data.map((row) => ({
            campaign: selectedCampaign,
            user: selectedUser,
            phone: formatPhoneNumber(row.phone),
            date: moment(row.date).format("MM/DD/YYYY"),
            procedure: selectedCampaign.name,
            provider: selectedUser.name,
        }));

        setSubmissions(submissions.concat(data));
        submissionsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const handleCSVError = (error) => {
        console.log("ERROR", error);
    };

    const handleAdd = () => {
        if (isImportEnabled) {
            setShowButtonTooltip(true);
            setTimeout(() => {
                setShowButtonTooltip(false);
            }, 3000);
        }

        const submission = {
            campaign: selectedCampaign,
            user: selectedUser,
            phone: formatPhoneNumber(mobileNumber),
            date: moment(eventDate).format("MM/DD/YYYY"),
            procedure: selectedCampaign.name,
            provider: selectedUser.name,
        };

        const prevSubmissions = [...submissions];

        setSubmissions((prevSubmissions) => [...prevSubmissions, submission]);

        setEventDate(defaultEventDate);

        setMobileNumber("1");

        submissionsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const handleQuickEnroll = () => {
        setSubmissions([
            {
                campaign: selectedCampaign,
                user: selectedUser,
                phone: formatPhoneNumber(mobileNumber),
                date: moment(eventDate).format("MM/DD/YYYY"),
                procedure: selectedCampaign.name,
                provider: selectedUser.name,
            },
        ]);
        setIsQuickEnrolling(true);
    };

    const handleToggle = (e) => {
        setIsOptedIn(e.target.checked);
    };

    useEffect(() => {
        if (!isLoading && queryUser && !selectedUser) {
            const getUserFromQuery = async () => {
                setIsLoading(true);
                const userRecord = await getUserRecord(queryUser);
                setUsers([userRecord]);
                handleSelectUser([userRecord]);
                setIsLoading(false);
                setShowRemoveButton(false);
            };
            !isLoading && getUserFromQuery();
        }
    }, [queryUser, selectedUser, isLoading, handleSelectUser]);

    useEffect(() => {
        if (!isLoading && queryCampaign && !selectedUser && !selectedCampaign) {
            const getCampaignForUser = async () => {
                setIsLoading(true);
                const userCampaign = await getCampaign(queryCampaign);
                setUsers([userCampaign.user]);
                setShowUser(false);
                setSelectedUser(userCampaign.user);
                setCampaigns([userCampaign]);
                setSelectedCampaign(userCampaign);

                if (userCampaign.hasQuickEnroll) {
                    setDefaultEventDate(today);
                    setEventDate(today);
                    setShowEventDate(false);
                }
                setIsLoading(false);
            };
            !isLoading && getCampaignForUser();
        }
    }, [queryCampaign, selectedUser, selectedCampaign, today, isLoading]);

    useEffect(() => {
        if (isQuickEnrolling) {
            setIsQuickEnrolling(false);
            enrollSubmissions();
        }
    }, [isQuickEnrolling, enrollSubmissions]);

    const [showButtonTooltip, setShowButtonTooltip] = useState(false);
    const [showExcelTooltip, setShowExcelTooltip] = useState(false);
    const [showSheetsTooltip, setShowSheetsTooltip] = useState(false);

    const hideTooltips = () => {
        showButtonTooltip && setShowButtonTooltip(false);
        showExcelTooltip && setShowExcelTooltip(false);
        showSheetsTooltip && setShowSheetsTooltip(false);
    };

    const handleClickExportUsingExcel = () => {
        setShowButtonTooltip(false);
        setShowSheetsTooltip(false);
        setShowExcelTooltip(!showExcelTooltip);
    };

    const handleClickExportUsingSheets = () => {
        setShowButtonTooltip(false);
        setShowExcelTooltip(false);
        setShowSheetsTooltip(!showSheetsTooltip);
    };

    useEffect(() => {
        console.log("Submissions:", submissions);
    }, [submissions]);

    const handleRemove = useCallback(
        (index) => {
            const prevSubmissions = submissions;
            prevSubmissions.splice(index, 1);
            setSubmissions([...prevSubmissions]);
        },
        [submissions]
    );

    return (
        <Form
            className={isLoading ? `mt-3 mb-3 loading` : `mt-3 mb-3`}
            onClick={hideTooltips}
            onSubmit={handleSubmit}
            ref={formRef}
            id="EnrollmentForm"
        >
            {isLoading ? (
                <>Loading...</>
            ) : (
                <>
                    <div id="alerts">
                        {alerts.map((a, index) => (
                            <Alert key={`alert-${index}`} variant={a.status}>
                                {a.message || a.error}
                            </Alert>
                        ))}
                    </div>

                    {showUser ? (
                        <SelectUser
                            isSearching={isSearching}
                            isImportEnabled={isImportEnabled}
                            onChange={handleSelectUser}
                            onFocus={handleDeselectUser}
                            onSearch={handleSearch}
                            users={users}
                            selectedUser={selectedUser}
                        />
                    ) : null}

                    {showCampaign && selectedUser && campaigns ? (
                        <SelectCampaign
                            campaigns={campaigns}
                            onChange={handleSelectCampaign}
                            selectedCampaign={selectedCampaign}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            setShowUser={setShowUser}
                            isImportEnabled={isImportEnabled}
                            resetForm={resetForm}
                            hideRemoveButton={!showRemoveButton}
                        />
                    ) : null}

                    {selectedCampaign && isImportEnabled ? (
                        <EnrollMultiple
                            eventDate={eventDate}
                            eventDateLabel={eventDateLabel}
                            formRef={formRef}
                            handleAdd={handleAdd}
                            handleCSV={handleCSV}
                            handleCSVError={handleCSVError}
                            handleClickExportUsingExcel={
                                handleClickExportUsingExcel
                            }
                            handleClickExportUsingSheets={
                                handleClickExportUsingSheets
                            }
                            handleToggle={handleToggle}
                            isValidDate={isValidDate}
                            isValidMobileNumber={isValidMobileNumber}
                            showEventDate={showEventDate}
                            showExcelTooltip={showExcelTooltip}
                            showSheetsTooltip={showSheetsTooltip}
                            mobileNumber={mobileNumber}
                            mobileNumberInput={mobileNumberInput}
                            selectedCampaign={setSelectedCampaign}
                            selectedUser={selectedUser}
                            setEventDate={setEventDate}
                            setShowButtonTooltip={setShowButtonTooltip}
                            setMobileNumber={setMobileNumber}
                        />
                    ) : selectedCampaign ? (
                        <EnrollSingle
                            buttonLabel={`Submit`}
                            buttonVariant="primary"
                            disabled={
                                !isOptedIn ||
                                !isValidMobileNumber ||
                                (!selectedCampaign.hasQuickEnroll &&
                                    !isValidDate)
                            }
                            eventDate={eventDate}
                            eventDateLabel={eventDateLabel}
                            formRef={formRef}
                            handleAdd={handleQuickEnroll}
                            handleToggleOptIn={handleToggle}
                            mobileNumber={mobileNumber}
                            mobileNumberInput={mobileNumberInput}
                            phoneLabel={`Enter your mobile phone number:`}
                            selectedUser={selectedUser}
                            setMobileNumber={setMobileNumber}
                            setEventDate={setEventDate}
                            showEventDate={showEventDate}
                            selectedCampaign={selectedCampaign}
                        />
                    ) : null}

                    {isImportEnabled ? (
                        <div id="submissions" ref={submissionsRef}>
                            {submissions.length ? (
                                <>
                                    <h6 style={{ marginLeft: 10 }}>
                                        {submissions.length === 1
                                            ? `${submissions.length} number will be imported:`
                                            : `${submissions.length} numbers will be imported:`}
                                    </h6>
                                    <TableContainer
                                        component={Paper}
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Mobile #
                                                    </TableCell>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>
                                                        Procedure
                                                    </TableCell>
                                                    <TableCell>
                                                        Provider
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {submissions.map(
                                                    (
                                                        {
                                                            phone,
                                                            date,
                                                            procedure,
                                                            provider,
                                                        },
                                                        index
                                                    ) => (
                                                        <TableRow
                                                            key={`row-${phone}`}
                                                            hover
                                                        >
                                                            <TableCell>
                                                                {phone}
                                                            </TableCell>
                                                            <TableCell>
                                                                {date}
                                                            </TableCell>
                                                            <TableCell>
                                                                {procedure}
                                                            </TableCell>
                                                            <TableCell>
                                                                {provider}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    key={`row-${phone}`}
                                                                    name={`row-${phone}`}
                                                                    size="sm"
                                                                    variant="danger"
                                                                    onClick={() =>
                                                                        handleRemove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <OptIn
                                        label={`By checking this box you are confirming the phone number(s) listed above have opted-in to receiving Medical Information and Promotional messages about Vidscrip Programs.`}
                                        onToggle={handleToggle}
                                        checked={isOptedIn}
                                        selectedCampaign={selectedCampaign}
                                    />
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            submissions.length === 0 ||
                                            isOptedIn === false ||
                                            isLoading
                                        }
                                    >
                                        Submit
                                    </Button>
                                </>
                            ) : null}
                        </div>
                    ) : null}
                    <input type="hidden" name="referrer" value={referrer} />
                    <input
                        type="hidden"
                        name="referrerType"
                        value={referrerType}
                    />
                    <input
                        type="hidden"
                        name="keyword"
                        value={queryKeyword || ""}
                    />
                </>
            )}
        </Form>
    );
};

export default EnrollmentForm;
