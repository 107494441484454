import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { PersonFill } from "react-bootstrap-icons";
import { useQuery } from "./Utils";

const SelectUser = (props) => {
    const queryUserLabel = useQuery().get("userLabel");
    const userLabel =
        queryUserLabel ||
        (props.isImportEnabled
            ? `Healthcare Provider (doctor, nurse, patient educator, etc.)`
            : `Who is your healthcare provider?`);

    let selectUserRef = useRef();

    const onFocus = () => {
        props.onFocus && props.onFocus(selectUserRef);
    };

    return (
        <Form.Group>
            <Form.Label>{userLabel}</Form.Label>
            <AsyncTypeahead
                ref={selectUserRef}
                isLoading={props.isSearching}
                id="user"
                minLength={2}
                labelKey="name"
                onFocus={onFocus}
                onChange={props.onChange}
                onInputChange={props.onInputChange}
                onSearch={props.onSearch}
                options={props.users}
                defaultSelected={props.selectedUser && [props.selectedUser]}
                placeholder="Search for a provider by name ..."
                renderMenuItemChildren={(option) => {
                    return (
                        <div className="user">
                            <div className="user--profile-photo">
                                {option.photoUrl ? (
                                    <Image
                                        className="user--profile-photo--image"
                                        src={option.photoUrl}
                                        alt={option.name}
                                    />
                                ) : (
                                    <PersonFill color={`#CCCCCC`} size={45} />
                                )}
                            </div>
                            <div className="user--details">
                                <div className="user--name">{option.name}</div>
                                <div className="user--meta">
                                    {option.location ? (
                                        <span className="user--location">
                                            {`${option.location} -`}
                                        </span>
                                    ) : null}
                                    <span className="user--specialty">
                                        {option.specialty}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                }}
            />
        </Form.Group>
    );
};

export default SelectUser;
